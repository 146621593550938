body {
  overflow-x: hidden;
}

header {
  /* font-size: 1.5rem; */
  /* padding: 1rem; */
  border-bottom: 1px solid #ccc;
  color: #aaa;
  margin-bottom: 1rem;
  transition: .4s linear all;
}

ul {
  padding: 0;
  text-indent: 0;
}

header:hover {
  color: #345;
}

footer{
  padding: 1rem;
  font-size: .9rem;
  color: #aaa;
}

header h1 {
  cursor: pointer;
  font-size: 1.5rem;
}

.App {
  text-align: center;
  font-family: 'Noto Sans TC', sans-serif;
  color: #777;
  font-weight: 300;
  padding: 0 .5rem;
  overflow-x: hidden;
}

.roomList {
  max-width: 1000px;
  margin: 0 auto;
}

.room {
  display: inline-block;
  margin: 1rem;
  cursor: pointer;
  transition: .3s linear all;
}

.room .image {
  opacity: 0.7;
  transition: opacity .5s ease-out;
}

.room:hover{
  color: #333;
}

.room:hover .image {
  opacity: 1;
}

.image {
  width: 300px;
  height: 400px;
  background-size: cover;
}

.roomDetail {
  text-align: left;
  max-width: 900px;
  margin: 0 auto;
}

.img {
  display: inline-block;
  width: 300px;
}

.detail {
  width: calc(90% - 300px);
  padding-left: 40px;
  display: inline-block;
}

@media screen and (max-width:767px) { 
  .img, .detail {
    width: 100%;
    margin: 0 auto;
  }

  .img {
    text-align: center;
  }

  .detail{ 
    padding: 0;
  }
}

.roomDetail .name {
  font-size: 2rem;
}

.roomDetail .image {
  opacity: 1;
}

input {
  width: 300px;
  border: 1px solid #ccc;
  outline: 0;
  padding: .5rem 1rem;
}

.contactName, .contactTel, .contactDate {
  margin-bottom: .5rem;
}

.name {
  font-size: 1.2rem;
  font-weight: 300;
}

.oops {
  color: #aaa;
  font-style: italic;
}

/* below are from https://codepen.io/mrtobo/pen/qpaEOj */

.blog {
  display: block;
  position: absolute;
  top: 1.5rem;
  right: -6rem;
  width: 18rem;
  line-height: 2em;
  height: 1.5em;
  line-height: 1.5rem;
  transform: rotate(45deg);
  background-color: #333;
  text-indent: .8em;
  cursor: pointer;
}

.blog a {
  position: relative;
  width: 100%;
  height: 100%;
  color: #aaa;
  display: block;
  text-align: center;
  text-decoration: none;
}
.blog a:hover {
  text-decoration: underline;
}
.blog a:before, .blog a:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  width: 100%;
  border-bottom: 1px dashed #dedede;
}
.blog a:after {
  bottom: 0;
  top: auto;
  border-bottom-width: 0;
  border-top: 1px dashed #dedede;
}